<template>
    <div v-if="fileVoideList.length==0">
        <uploader
                :autoStart="false"
                :options="options"
                :file-status-text="statusText"
                class="uploader-example"
                @file-complete="fileComplete"
                @complete="complete"
                @file-success="fileSuccess"
                @files-added="filesAdded"
        >
            <uploader-unsupport></uploader-unsupport>
            <uploader-drop>
                <uploader-btn :attrs="attrs">选择视频</uploader-btn>
            </uploader-drop>
            <!-- <uploader-list></uploader-list> -->
            <uploader-files> </uploader-files>
        </uploader>
        <br />
<!--        <el-button @click="allStart()" type="primary" :disabled="disabled">全部开始</el-button>-->
<!--        <el-button @click="allStop()" type="success" style="margin-left: 4px">全部暂停</el-button>-->
<!--        <el-button @click="allRemove()" type="danger" style="margin-left: 4px">全部移除</el-button>-->
    </div>
    <div v-else>
        <vpload-video :limit="1" v-bind:file-list="fileLists" :type="3"></vpload-video>
    </div>
</template>

<script>
    import VploadVideo from "./VploadVideo";
    import ty from "../../utils/ty";
    import SparkMD5 from "spark-md5";
    export default {
        components:{
            VploadVideo
        },
        name: "ComponentUploadImage",
        props:{
            fileVoideList:{
                type:Array,
                default: ()=>{
                    return []
                }
            },
            limit:{
                type:Number,
                default:12,
            },
        },
        data() {
            return {
                skip: false,
                dialogVisible: false,
                options: {
                    singleFile:true,
                    forceChunkSize:true,
                    chunkSize: 2 * 1024 * 1024, // 允许片段最大为5M，因为最后一块默认是可以大于等于这个值，但必须小于它的两倍。
                    simultaneousUploads: 1, // 最大并发上传数
                    successStatuses:[0,200],
                    target: ty.apiurl+"/upload/chunk",
                    // 开启服务端分片校验功能
                    testChunks: true,
                    parseTimeRemaining: function (timeRemaining, parsedTimeRemaining) {
                        return parsedTimeRemaining
                            .replace(/\syears?/, "年")
                            .replace(/\days?/, "天")
                            .replace(/\shours?/, "小时")
                            .replace(/\sminutes?/, "分钟")
                            .replace(/\sseconds?/, "秒");
                    },
                    // 服务器分片校验函数
                    checkChunkUploadedByResponse: (chunk, message) => {
                        console.log('rrrrer',message)
                        const result = JSON.parse(message);
                        console.log('swasdxccx x',result.data.skipUpload)
                        if (result.data.skipUpload) {
                            this.skip = true;
                            return true;
                        }
                        return (result.data.uploaded || []).indexOf(chunk.offset + 1) >= 0;
                    },
                },
                attrs: {
                    accept: "video/*",
                },
                statusText: {
                    success: "上传成功",
                    error: "上传出错了",
                    uploading: "上传中...",
                    paused: "暂停中...",
                    waiting: "等待中...",
                    cmd5: "计算文件MD5中...",
                },
                fileList:[],
                fileLists:this.fileVoideList,
                disabled: true,
                hideUploadEdit:this.fileVoideList.length>=1,
            };
        },
        watch: {
            fileList(o, n) {
                console.log("zsssass")
                this.disabled = false;

            },
            fileVoideList(o,n){
                this.fileLists=n;
            }
        },
        methods: {
            fileSuccess(rootFile, file, response, chunk) {
                const result = JSON.parse(response);
                if (!this.skip) {
                    this.$axios.post("/upload/merge", {identifier: file.uniqueIdentifier, filename: file.name, totalChunks: chunk.offset}).then((res) => {
                            if (res.code==0) {
                                console.log("上传成功");
                                this.submitFile(res.msg,1)
                                let obj = {
                                    name: file.name,
                                    status: "success",
                                    uid: file.uid,
                                    url: res.msg
                                }
                                this.fileLists.push(obj)
                            } else {
                                console.log(res);
                            }
                        })
                        .catch(function (error) {
                            console.log(error);
                        });
                } else {
                    console.log("上传成功，不需要合并");
                }
                if (this.skip) {
                    this.skip = false;
                }
            },
            fileComplete(rootFile) {
                // 一个根文件（文件夹）成功上传完成。
                // console.log("fileComplete", rootFile);
                // console.log("一个根文件（文件夹）成功上传完成。");
            },
            complete() {
                // 上传完毕。
                // console.log("complete");
            },
            filesAdded(file, fileList, event) {
                // console.log(file);
                file.forEach((e) => {
                    this.fileList.push(e);
                    this.computeMD5(e);
                });
            },
            computeMD5(file) {
                let fileReader = new FileReader();
                let time = new Date().getTime();
                let blobSlice =
                    File.prototype.slice ||
                    File.prototype.mozSlice ||
                    File.prototype.webkitSlice;
                let currentChunk = 0;
                const chunkSize = 1024 * 1024;
                let chunks = Math.ceil(file.size / chunkSize);
                let spark = new SparkMD5.ArrayBuffer();
                // 文件状态设为"计算MD5"
                file.cmd5 = true; //文件状态为“计算md5...”
                file.pause();
                loadNext();
                fileReader.onload = (e) => {
                    spark.append(e.target.result);
                    if (currentChunk < chunks) {
                        currentChunk++;
                        loadNext();
                        // 实时展示MD5的计算进度
                        console.log(
                            `第${currentChunk}分片解析完成, 开始第${
                                currentChunk + 1
                            } / ${chunks}分片解析`
                        );
                    } else {
                        let md5 = spark.end();
                        console.log(
                            `MD5计算完毕：${file.name} \nMD5：${md5} \n分片：${chunks} 大小:${
                                file.size
                            } 用时：${new Date().getTime() - time} ms`
                        );
                        spark.destroy(); //释放缓存
                        file.uniqueIdentifier = md5; //将文件md5赋值给文件唯一标识
                        file.cmd5 = false; //取消计算md5状态
                        file.resume(); //开始上传
                    }
                };
                fileReader.onerror = function () {
                    this.error(`文件${file.name}读取出错，请检查该文件`);
                    file.cancel();
                };
                function loadNext() {
                    let start = currentChunk * chunkSize;
                    let end =
                        start + chunkSize >= file.size ? file.size : start + chunkSize;
                    fileReader.readAsArrayBuffer(blobSlice.call(file.file, start, end));
                }
            },
            allStart() {
                console.log(this.fileList);
                this.fileList.map((e) => {
                    if (e.paused) {
                        e.resume();
                    }
                });
            },
            allStop() {
                console.log(this.fileList);
                this.fileList.map((e) => {
                    if (!e.paused) {
                        e.pause();
                    }
                });
            },
            allRemove() {
                this.fileList.map((e) => {
                    e.cancel();
                });
                this.fileList = [];
                this.submitFile('',1)
            },

            // 移除图片
            handleRemove(file) {
                for(let i in this.fileLists){
                    if(this.fileLists[i].uid == file.uid){
                        this.fileLists.splice(i,1)
                    }
                }
                this.hideUploadEdit = this.fileLists.length>=1;
                this.fileList.map((e) => {
                    e.cancel();
                });
                this.fileList = [];
                this.submitFile(file.url,0)
            },
            handleChange(file,fileList){
                this.hideUploadEdit = fileList.length>=1;
            },
            handlePictureCardPreview(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible = true;
            },
            // 将图片文件传回给父组件
            submitFile(filePath,add){
                //add:0是删除。1是新增
                this.$emit('submitImg',filePath,add)
            },
        },
    };
</script>

<style>
    .uploader-example {
        width: 99%;
        padding: 15px;
        margin: 0px auto 0;
        font-size: 12px;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    }
    .uploader-example .uploader-btn {
        margin-right: 4px;
    }
    .uploader-example .uploader-list {
        max-height: 440px;
        overflow: auto;
        overflow-x: hidden;
        overflow-y: auto;
    }

    .el-icon-plus{
        font-size: 30px!important;
    }

    .el-icon-zoom-in{
        font-size: 18px !important;
    }
    .el-icon-delete{
        font-size: 18px !important;
        color:rgb(243, 143, 130);
    }
    .el-input>>> .el-textarea__inner{
        font-size:18px!important;
    }
    .hide  >>> .el-upload--picture-card{
        display: none;
    }
</style>
